<template lang="pug">
	.container
		.wallet-form-box.card
			.card-body
				.row
					.col-sm-7
						h4 Nepal Water Supply

						transition(name='fade')
							.form-group(v-if='message')
								.show-message {{message}}
						transition(name='fade')
							.ml-3(v-if="messageDetails")
								.mt-3
									div(class="row"
										class="mt-3"
										v-for="(message, index) in messageDetails"
										:key="index")
										label.text-capitalize {{index}}
										input(:value='message' class="form-control form-control-sm" disabled)
								.form-group.mt-5
									show-bank(:bankClicked="bankClicked" :banks="banks" v-if="!loading")
							form(@submit.prevent="getDetails" v-else)
								.form-group
									label Counter
										i.fa.fa-asterisk
									select(
										class="form-control"
										v-model="nwsDetails.OfficeCode"
										:disabled="loading"
										required
									)
										option(:value="null" disabled) Select Office
										option(v-for="counter in allCounter"
											:key="counter.OfficeCodes"
											:value="counter.OfficeCodes") {{counter.Office}}
								.form-group
									label Customer ID
										i.fa.fa-asterisk
									input(type="text" class="form-control" v-model="nwsDetails.CustomerId" required )
								.form-group
									button.btn.btn-danger(:disabled='loading') Get Details
					.col-sm-5
						pin-confirm(v-if='paymentModal' :previewDetails='previewDetails' :pinVerification='verifyPin')
		loading-spinner(:loading='loading')
</template>

<script>
import ShowBank from "@/components/ShowBank";
import LoadingSpinner from "@/components/LoadingSpinner";
import PinConfirm from "@/components/PinConfirm";
import api from "@/helpers/api";
import payment from "@/mixins/payment";
import axios from "@/axios";

export default {
  mixins: [payment],
  name: "utility-water",
  components: { ShowBank, LoadingSpinner, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Customer ID": this.nwsDetails.CustomerId,
        "Total Amount to Pay": "Rs. " + this.nwsDetails.amount || 0,
      };
    },
  },
  data: function () {
    return {
      message: "",
      allCounter: "",
      nwsDetails: {
        CustomerId: "",
        OfficeCode: null,
      },
      messageDetails: "",
      banks: "",
      loading: true,
    };
  },
  methods: {
    verifyPin(verified) {
      this.pinVerification(this.makePayment, verified);
    },
    bankClicked(bank) {
      this.nwsDetails.amount = this.messageDetails["totalAmount"];

      if (this.nwsDetails.amount !== "0.00") {
        this.nwsDetails.bank = bank;
        if (this.pinVerified) this.makePayment();
        else {
          this.paymentModal = true;
          axios
            .get("rewards/calculate", {
              params: {
                amount: this.nwsDetails.amount,
                servicesId: this.nwsDetails.servicesId,
              },
            })
            .then((res) => {
              if (res.data && res.data.data) {
                this.nwsDetails.reward = res.data.data.earningRewards;
              }
            });
        }
      } else {
        this.message = "You dont have any due amount..";
      }
    },
    makePayment() {
      this.loading = true;
      axios
        .post("api/v4/nws/payment", this.nwsDetails)
        .then((res) => {
          if (res.data) {
            if (res.data.status === 1) {
              this.message = res.data.message;
              this.messageDetails = "";
              this.preview = false;
              this.$store.dispatch("UPDATE_BALANCE");
            } else {
              this.message = res.data.message;
            }
            this.loading = false;
          }
        })
        .catch((_) => {
          this.message = "Something went wrong.";
          this.loading = false;
        });
    },

    getDetails() {
      this.loading = true;
      axios
        .post("api/v2/services/nws/bill", this.nwsDetails)
        .then((r) => {
          if (r.data && r.data.response && r.data.response.Code === "000") {
            this.messageDetails = r.data.response;
          } else {
            this.message = "Unable to fetch bill detail for provided information";
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.message = "Something went wrong.";
        });
    },

    /**
     * Beautify NWS Details
     * @param{array} rawArray array contains information in key value pair in form of array.
     *
     * This function converts array value pair in object key value pair.
     * Formatting <br>'s into html table structure.
     */
    beautifyNwsDetails(rawArray) {
      let formatted = {};
      rawArray.forEach((inn) => {
        formatted[inn.keyTitle.replace(/-/g, " ")] = inn.valueTitle;
      });
      return formatted;
    },
  },
  async mounted() {
    let offices = await api.nwsOfficeCodes(this.nwsDetails.servicesId);
    if (offices) {
      this.allCounter = offices;
      this.banks = await api.banks();
    } else {
      this.message = "Could not fetch Office Codes.";
    }
    this.loading = false;
  },
};
</script>
